import React from 'react'

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutUs = () => {

    return (
        <Layout>
            <Seo title="Products" />

            <section className="py-20">
                <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
                    <h2 className="w-full text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">About us</h2>
                    <p className="mb-5 text-lg text-gray-700 text md:text-left text-center mx-auto max-w-4xl">
                        Brief history to follow.
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export default AboutUs
